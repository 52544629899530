import { useEffect } from 'react'

import { useEditFacility } from '../hooks/useEditFacility'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle, HiOutlineOfficeBuilding } from 'react-icons/hi'

import {
  Box,
  FormErrorMessage,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import {
  AddressSearch,
  Button,
  FormInputControl,
  FormInputSelectControl,
  PinMarker,
  SkeletonModalBody,
  StaticMapBox,
} from '@/components/ui'
import { StyledFormInputControl } from '@/components/ui'
import { EmergencyCallConfigurationStatus } from '@/features/emergency-call'
import { FacilityType, UpdateFacilityInput } from '@/graphql/generated/schemas'

import { FormInputsIProps } from '../types/types'
import {
  facilityTypeOptions,
  getErrorMessage,
  getFacilityTypeOption,
} from '../utils/utils'

interface EditFacilityModalIProps {
  facilityId: string
  isOpen: boolean
  onClose: () => void
}

export const EditFacilityModal = ({
  isOpen,
  onClose,
  facilityId,
}: EditFacilityModalIProps) => {
  const {
    handleSubmit,
    errors,
    trigger,
    register,
    formState,
    setValue,
    getValues,
    control,
  } = useForm({
    mode: 'onChange',
  })
  const { isValid, isSubmitting } = formState
  const { showSuccess, showError } = useToasts()
  const { shouldEnableUpdateFacility } = usePermissions()
  const {
    update,
    facility,
    floors,
    defaultFloor,
    isLoading,
    error,
    isEmergencyCallEnabled,
    isEmergencyCallConfigured,
  } = useEditFacility(facilityId)

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const input: UpdateFacilityInput = {
      id: facilityId,
      name: values.name,
      shortName: values.shortName.toLocaleUpperCase(),
      type: values.type?.value,
      defaultFloorId: values?.defaultFloor?.value,
      emergencyNumber: values.emergencyNumber,
      address: values?.address ? [values.address] : null,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    }
    try {
      await update(input)
      showSuccess(`Successfully updated ${values.name} Facility`)
      onClose()
    } catch (e) {
      showError(getErrorMessage(e, values.name, 'updating'))
    }
  }

  useEffect(() => {
    if (facility && !isLoading) {
      setValue('name', facility.name, { shouldValidate: true })
      setValue('shortName', facility.shortName, {
        shouldValidate: true,
      })
      setValue('address', facility.address?.join(', '), {
        shouldValidate: true,
      })
      setValue('emergencyNumber', facility.emergencyNumber, {
        shouldValidate: false,
      })
      setValue('latitude', facility.latitude, {
        shouldValidate: true,
      })
      setValue('longitude', facility.longitude, {
        shouldValidate: true,
      })
    }
  }, [isLoading, facility])

  useEffect(() => {
    register('address')
    register('latitude')
    register('longitude')
  }, [])

  return (
    <Modal
      id='facilitiesPage_editFacilityModal'
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' overflow='hidden'>
        <ModalCloseButton color='#000' opacity={0.4} />
        {error ? (
          <>
            <ModalBody p='0'>
              <Box px='10' py='6'>
                <Grid gap='10px' gridTemplateColumns='30px 1fr'>
                  <HiOutlineOfficeBuilding size='30px' />
                  <Box>
                    <Box
                      color='#353849'
                      fontSize='26px'
                      fontWeight='extrabold'
                      letterSpacing='-0.4px'
                      lineHeight='1'
                      mb='3'
                    >
                      Edit Facility
                    </Box>
                    <Box
                      color='#2D2E41'
                      fontSize='17px'
                      letterSpacing='-0.4px'
                      mt='3'
                    >
                      This facility is not found.
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                data-testid='facilitiesPage_editFacilityModal_closeButton'
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody p='0'>
              {!facility || isLoading ? (
                <SkeletonModalBody />
              ) : (
                <>
                  <Box px='10' py='6'>
                    <Grid gap='10px' gridTemplateColumns='30px 1fr'>
                      <HiOutlineOfficeBuilding size='30px' />
                      <Box>
                        <Box
                          color='#353849'
                          fontSize='26px'
                          fontWeight='extrabold'
                          letterSpacing='-0.4px'
                          lineHeight='1'
                          mb='3'
                        >
                          Edit Facility
                        </Box>
                        <Box
                          color='#2D2E41'
                          fontSize='17px'
                          letterSpacing='-0.4px'
                          mt='3'
                        />
                      </Box>
                    </Grid>
                  </Box>
                  <Box>
                    <form>
                      <Box px='6'>
                        <Box mb='3'>
                          <FormInputControl
                            data-testid='EditFacilityModal:name'
                            errorMessage={errors.name && errors.name.message}
                            id='name'
                            inputRef={register({
                              required: 'Facility Name is required',
                            })}
                            isInvalid={errors.name}
                            label='Facility Name'
                            placeholder='Enter the Facility Name'
                          />
                        </Box>
                        <Box mb='3'>
                          <FormInputControl
                            data-testid='EditFacilityModal:shortName'
                            errorMessage={
                              errors.shortName && errors.shortName.message
                            }
                            id='shortName'
                            inputRef={register({
                              required: 'Facility Initials are required',
                              maxLength: {
                                value: 4,
                                message: 'Must be less than 4 characters',
                              },
                            })}
                            isInvalid={errors.shortName}
                            label='Short Name'
                            placeholder='Enter the Facility Initials'
                          />
                        </Box>
                        <Box mb='3'>
                          <FormInputSelectControl
                            control={control}
                            dataTestId='createFacility_facilityName'
                            defaultValue={getFacilityTypeOption(facility)}
                            id='type'
                            isDisabled={true}
                            isInvalid={errors.type}
                            label='Facility Type'
                            options={facilityTypeOptions}
                            placeholder='Select the Facility type'
                            rules={{ required: true }}
                          />
                        </Box>

                        {facility?.type === FacilityType.Normal &&
                          defaultFloor && (
                            <Box mb='3'>
                              <FormInputSelectControl
                                control={control}
                                dataTestId='createFacility_deafultFloor'
                                defaultValue={defaultFloor}
                                id='defaultFloor'
                                label='Default floor'
                                options={floors}
                                placeholder='Select the default floor'
                              />
                            </Box>
                          )}

                        {facility?.type === FacilityType.Normal && (
                          <StyledFormInputControl mb='4'>
                            <FormLabel>Address</FormLabel>
                            <AddressSearch
                              defaultValue={facility.address?.join(', ')}
                              disabled={true}
                              onSelectAddress={(address) => {
                                setValue('address', address.value)
                                setValue('latitude', address.latitude)
                                setValue('longitude', address.longitude)
                                trigger(['address', 'latitude', 'longitude'])
                              }}
                            />
                            <FormErrorMessage>
                              {errors.address && errors.address.message}
                            </FormErrorMessage>
                          </StyledFormInputControl>
                        )}

                        {isEmergencyCallConfigured && (
                          <Box mb='3'>
                            <FormInputControl
                              control={control}
                              data-testid='EditFacilityModal:emergencyNumber'
                              errorMessage={
                                errors.emergencyNumber &&
                                errors.emergencyNumber.message
                              }
                              id='emergencyNumber'
                              inputRef={register()}
                              isInvalid={errors.emergencyNumber}
                              label='Emergency Number'
                              placeholder='Enter the Emergency Number for this Facility'
                            />
                          </Box>
                        )}
                      </Box>
                      <EmergencyCallConfigurationStatus
                        isConfigured={isEmergencyCallConfigured}
                        isEnabled={isEmergencyCallEnabled}
                      />

                      {facility?.type !== FacilityType.Market && (
                        <StaticMapBox
                          height='150px'
                          latitude={getValues('latitude') || facility?.latitude}
                          longitude={
                            getValues('longitude') || facility?.longitude
                          }
                        >
                          <PinMarker
                            Icon={HiOutlineOfficeBuilding}
                            bgGradient='linear(to-r, #1CFDE5, #07DB94)'
                            borderColor='#42CD70'
                            fadedPulseBoxShadow='rgba(43, 210, 180, 0)'
                            pulseBoxShadow='rgba(43, 210, 180, 0.4)'
                          />
                        </StaticMapBox>
                      )}
                    </form>
                  </Box>
                </>
              )}
            </ModalBody>
            {facility && !isLoading && (
              <ModalFooter py='4'>
                <Button
                  data-testid='facilitiesPage_editFacilityModal_cancelButton'
                  mr='3'
                  onClick={onClose}
                  variant='secondary'
                >
                  Cancel
                </Button>
                <Button
                  data-testid='facilitiesPage_editFacilityModal_saveButton'
                  isDisabled={!isValid || !shouldEnableUpdateFacility}
                  isLoading={isSubmitting}
                  leftIcon={<HiOutlineCheckCircle size='24px' />}
                  loadingText='Saving Changes'
                  onClick={handleSubmit(onSubmit)}
                >
                  Save Changes
                </Button>
              </ModalFooter>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

import { memo, useEffect, useMemo, useRef } from 'react'

import { useAvailableIncidentActions } from '../hooks/useAvailableIncidentActions'
import { useTabs } from '../hooks/useTabs'
import { BiWrench } from 'react-icons/bi'
import { HiOutlineShieldCheck } from 'react-icons/hi'
import { useSelector } from 'react-redux'

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import { Note } from '@/components/icons'
import { EmergencyCallButton } from '@/features/emergency-call'
import { useIncidentQuery } from '@/graphql/generated/hooks'
import { selectMe } from '@/redux/me/meSlice'
import { selectIncident } from '@/redux/ui/uiSlice'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { AddNote } from '../add-note'
import { AssociateCaseModal } from '../associate-case'
import { Feed } from '../feed'
import { OrderRepair } from '../order-repair'
import { RadioGuards } from '../radio-guards'
import {
  getGuardActionIcon,
  getGuardActionText,
} from '../radio-guards/utils/guardActions'
import { Resolve } from '../resolve'
import { SOPIncidentDrawer } from '../sop-incident-drawer/components/SOPIncidentDrawer'
import {
  IncidentAction,
  IncidentActionWithPanelIProps,
  MixpanelDataIProps,
} from '../types/types'
import { AdditionalOptionsButton } from './AdditionalOptionsButton'
import { IncidentActions } from './IncidentActions'
import { IncidentDrawerHeader } from './IncidentDrawerHeader'
import { IncidentMedia } from './IncidentMedia'

export interface IncidentDrawerIProps {
  isOpen: boolean
  onClose: () => void
}

export const IncidentDrawer = memo(
  ({ isOpen, onClose }: IncidentDrawerIProps) => {
    const incidentId = useSelector(selectIncident)?.id
    const me = useSelector(selectMe)
    const bodyRef = useRef<HTMLDivElement>()
    const { tabIndex, selectTab, closeTabs } = useTabs()

    const {
      isOpen: isAssociateCaseModalOpen,
      onOpen: onAssociateCaseModalOpen,
      onClose: onAssociateCaseModalClose,
    } = useDisclosure()

    const {
      data,
      loading: isIncidentsLoading,
      refetch: refetchFeedItems,
    } = useIncidentQuery({
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        id: incidentId ?? '',
      },
    })

    const isLoading = !data || isIncidentsLoading
    const incident = data?.incident
    const device = incident?.devices[0]
    const isArchived = incident?.facility?.archived

    const shouldDispatch = !incident?.dispatched
    const shouldShowIncidentActions = !(isLoading || isArchived)

    const scrollToTop = () => {
      bodyRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const mixpanelData: MixpanelDataIProps = useMemo(() => {
      if (data) {
        return {
          incident_id: incidentId,
          incident_source: device?.type,
          incident_type: incident?.type,
          incident_status: incident?.status,
          device_id: device?.id,
          device_type: device?.type,
          facility_id: incident?.facility?.id,
          facility_address: incident?.facility?.address,
          floor_id: incident?.floor?.id,
          floor_name: incident?.floor?.name,
        }
      }
    }, [incidentId, data, incident?.type, device])

    useEffect(() => {
      if (isOpen) {
        if (data) {
          const date = new Date().toUTCString()
          mixpanel.track('Active Incident View, Drawer View', {
            user_id: me?.id,
            incident_id: incident?.id,
            event_at: date,
            incident_source: device?.type,
            incident_type: incident?.type,
            device_id: device?.id,
            device_type: device?.type,
            facility_id: incident?.facility?.id,
          })
        }
      }
    }, [isOpen, incidentId, data, incident?.type, device])

    const onActionComplete = () => {
      refetchFeedItems()
      closeTabs()
    }

    const {
      isEmergencyCallEnabled,
      isAddNoteEnabled,
      isDispatchEnabled,
      isRadioGuardsEnabled,
      isOrderRepairEnabled,
      isResolveEnabled,
      isDownloadReportEnabled,
      isAdditionalOptionsEnabled,
    } = useAvailableIncidentActions({
      incidentStatus: incident?.status,
      incidentCategory: incident?.manualIncidentCategory,
      ownerId: incident?.owner?.id,
      outboundPhoneNumber:
        incident?.facility?.emergencyCalling?.outboundPhoneNumber,
    })

    const incidentActions: IncidentAction[] = [
      {
        isAvailable: isAddNoteEnabled,
        label: 'Add Note',
        id: 'addNote',
        icon: Note,
        panel: (
          <AddNote
            incidentId={incidentId}
            mixpanelData={mixpanelData}
            onActionComplete={onActionComplete}
          />
        ),
      },
      {
        isAvailable: shouldDispatch ? isDispatchEnabled : isRadioGuardsEnabled,
        label: getGuardActionText(shouldDispatch)?.actionName,
        id: `${shouldDispatch ? 'dispatch' : 'radio'}Guards`,
        icon: getGuardActionIcon(shouldDispatch),
        panel: (
          <RadioGuards
            facilityId={incident?.facility?.id}
            incidentId={incident?.id}
            mixpanelData={mixpanelData}
            onActionComplete={onActionComplete}
            shouldDispatch={shouldDispatch}
          />
        ),
      },
      {
        isAvailable: isOrderRepairEnabled,
        label: 'Order Repair',
        id: 'orderRepair',
        icon: BiWrench,
        panel: (
          <OrderRepair
            deviceType={device?.type}
            facilityId={incident?.facility?.id}
            incidentId={incident?.id}
            incidentStatus={incident?.status}
            mixpanelData={mixpanelData}
            onActionComplete={onActionComplete}
          />
        ),
      },
      {
        isAvailable: isEmergencyCallEnabled,
        id: 'emergencyCall',
        // eslint-disable-next-line react/no-unstable-nested-components
        customActionTab: (props) => (
          <EmergencyCallButton
            incident={incident}
            mixpanelData={mixpanelData}
            {...props}
          />
        ),
      },
      {
        isAvailable: isResolveEnabled,
        label: 'Resolve',
        id: 'resolve',
        icon: HiOutlineShieldCheck,
        panel: (
          <Resolve
            incident={incident}
            mixpanelData={mixpanelData}
            onActionComplete={onActionComplete}
          />
        ),
      },
      {
        isAvailable: isAdditionalOptionsEnabled,
        id: 'addionalOptions',
        // eslint-disable-next-line react/no-unstable-nested-components
        customActionTab: () => (
          <AdditionalOptionsButton
            incident={incident}
            isDownloadReportEnabled={isDownloadReportEnabled}
            onAssociateCaseModalOpen={onAssociateCaseModalOpen}
          />
        ),
      },
    ]

    const availableIncidentActions = incidentActions.filter(
      (a) => a.isAvailable
    )

    // "Calculate" tab index for each action associated with panel
    // All other actions are excluded and doesn't have tab index
    // This is a workaround to enable adding custom button along other tabs controlled by Chakra
    availableIncidentActions
      .filter((a) => 'panel' in a)
      .forEach((a: IncidentActionWithPanelIProps, i) => {
        a.tabIndex = i
      })

    return (
      <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='lg'>
        <DrawerOverlay />
        <DrawerContent boxShadow='xl'>
          <SOPIncidentDrawer />
          <DrawerHeader
            bgColor='#ffffff'
            borderBottom='1px solid #D5DCE4'
            p={0}
            pr='24px'
          >
            <IncidentDrawerHeader
              incident={incident}
              isLoading={isLoading}
              onClose={onClose}
            />
          </DrawerHeader>
          <DrawerBody bgColor='#ffffff' p={0} ref={bodyRef}>
            <IncidentMedia
              incident={incident}
              isLoading={isLoading}
              mixpanelData={mixpanelData}
              scrollToTop={scrollToTop}
            />
            {shouldShowIncidentActions && (
              <IncidentActions
                incident={incident}
                incidentActions={availableIncidentActions}
                mixpanelData={mixpanelData}
                onTabClick={(index: number, label: string) => {
                  selectTab(index)
                  mixpanel.track(`${BUTTON_PRESS} ${label}`, mixpanelData)
                }}
                tabIndex={tabIndex}
              />
            )}
            <Feed incident={incident} mixpanelData={mixpanelData} />
          </DrawerBody>
        </DrawerContent>

        <AssociateCaseModal
          incidentId={incident?.id}
          isOpen={isAssociateCaseModalOpen}
          onClose={onAssociateCaseModalClose}
        />
      </Drawer>
    )
  }
)

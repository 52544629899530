import { useState } from 'react'

import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler } from 'react-hook-form'

import {
  CaseDocument,
  useFacilitiesAndUsersQuery,
  useUpdateCaseDetailsMutation,
} from '@/graphql/generated/hooks'
import { getFacilityOptions, getUserOptions } from '@/utils/forms/selectOptions'

import { UpdateCaseFormInputsIProps } from '../types/types'

interface UseUpdateCaseIProps {
  onClose: () => void
  caseId: string
}

export const useUpdateCase = ({ onClose, caseId }: UseUpdateCaseIProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showError } = useToasts()

  const { data: optionsData } = useFacilitiesAndUsersQuery({
    fetchPolicy: 'network-only',
  })

  const [updateCase] = useUpdateCaseDetailsMutation()

  const facilityOptions = getFacilityOptions(optionsData)
  const userOptions = getUserOptions(optionsData)

  const onUpdateCase: SubmitHandler<UpdateCaseFormInputsIProps> = async (
    values
  ) => {
    setIsLoading(true)
    try {
      await updateCase({
        variables: {
          caseId: caseId,
          caseName: values?.name,
          caseFacilityIds: values?.facilities?.map((f) => f.value) || [],
          caseOwnerId: values?.owner?.value,
          status: values?.status?.value,
        },
        refetchQueries: [
          {
            query: CaseDocument,
            variables: { id: caseId },
          },
        ],
      })
      showSuccess(`Successfully updated ${values?.name} Case`)
      setIsLoading(false)
      onClose()
    } catch (error) {
      showError(error.message)
      setIsLoading(false)
    }
  }

  return {
    facilityOptions,
    userOptions,
    isLoading,
    onUpdateCase,
  }
}

import { useUpdateCase } from '../hooks/useUpdateCase'
import { usePermissions } from '@/hooks/usePermissions'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { Box } from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { CaseQuery } from '@/graphql/generated/operations'
import {
  caseUpdateStatusOptions,
  getDefaultCaseLabel,
  getFacilityOption,
  getUserOption,
} from '@/utils/forms/selectOptions'

import { UpdateCaseFormInputsIProps } from '../types/types'

interface UpdateCaseFormIProps {
  caseData: CaseQuery
  onClose: () => void
}

export const UpdateCaseForm = ({ caseData, onClose }: UpdateCaseFormIProps) => {
  const { shouldEnableCaseManagement } = usePermissions()

  const { onUpdateCase, isLoading, facilityOptions, userOptions } =
    useUpdateCase({
      onClose,
      caseId: caseData?.case?.id,
    })
  const { handleSubmit, register, control, errors, formState } =
    useForm<UpdateCaseFormInputsIProps>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        name: caseData?.case?.name,
        facilities: caseData?.case?.facilities?.map(getFacilityOption) || [],
        owner: caseData?.case?.owner
          ? getUserOption(caseData.case.owner)
          : null,
        status: {
          label: getDefaultCaseLabel(caseData?.case?.status),
          value: caseData?.case?.status,
        },
      },
    })
  const { isValid, isDirty } = formState

  return (
    <Box>
      <Box py={2}>
        <Box mb={3}>
          <FormInputControl
            errorMessage={errors.name && errors.name.message}
            id='name'
            inputRef={register({
              required: 'Case name is required',
            })}
            isDisabled={isLoading}
            isInvalid={!!errors.name}
            label='Case Name'
            placeholder='Enter a name for this Case'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            defaultValue={null}
            errorMessage={!!errors?.facilities && 'Facilities are required'}
            id='facilities'
            isClearable
            isDisabled={isLoading}
            isInvalid={!!errors.facilities}
            isMulti
            isSearchable
            label='Facilities'
            options={facilityOptions}
            placeholder='Add facilities where this Case applies'
            rules={{
              required: true,
              validate: (values) => values.length !== 0,
            }}
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={true}
            control={control}
            defaultValue={null}
            errorMessage={!!errors?.owner && 'Please select new owner first'}
            id='owner'
            isClearable
            isInvalid={!!errors.owner}
            isSearchable
            label='Owner'
            options={userOptions}
            placeholder='Select case owner'
            rules={{ required: true }}
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={true}
            control={control}
            defaultValue={null}
            errorMessage={!!errors?.status && 'Please select new status first'}
            id='status'
            isInvalid={!!errors.status}
            isSearchable={false}
            label='Status'
            options={caseUpdateStatusOptions}
            rules={{ required: true }}
          />
        </Box>
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <Button mr={3} onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!shouldEnableCaseManagement || !isValid || !isDirty}
          isLoading={isLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          loadingText='Updating Case'
          onClick={handleSubmit(onUpdateCase)}
        >
          Update Case
        </Button>
      </Box>
    </Box>
  )
}

import { useState } from 'react'

import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler } from 'react-hook-form'

import { useAssociateMultipleIncidentsToSingleCaseMutation } from '@/graphql/generated/hooks'
import {
  useCreateCaseMutation,
  useFacilityNamesQuery,
} from '@/graphql/generated/hooks'
import { getFacilityOptions } from '@/utils/forms/selectOptions'

import { CreateCaseFormInputsIProps } from '../types/types'

interface UseCreateCaseIProps {
  incidentId?: string
  onClose: () => void
}

export const useCreateCase = ({ incidentId, onClose }: UseCreateCaseIProps) => {
  const [createCase] = useCreateCaseMutation()
  const [associateIncidentsToCase] =
    useAssociateMultipleIncidentsToSingleCaseMutation()
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showError } = useToasts()

  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })

  const facilityOptions = getFacilityOptions(facilitiesData)

  const onCreateCase: SubmitHandler<CreateCaseFormInputsIProps> = async (
    values
  ) => {
    const facilityIds = values.facilities?.map((f) => f.value) || []
    setIsLoading(true)
    try {
      const { data } = await createCase({
        variables: {
          input: {
            description: values?.description,
            facilityIds,
            name: values?.name,
          },
        },
        refetchQueries: ['Cases'],
      })
      const caseReturnedName = data.createCase?.case?.name
      const caseId = data.createCase?.case?.id
      // If incidentId, associate it with the case
      if (incidentId) {
        await associateIncidentsToCase({
          variables: { input: { caseId, incidentIds: [incidentId] } },
        })
      }

      showSuccess(`Successfully created ${caseReturnedName}`)
      setIsLoading(false)
      onClose()
    } catch (error) {
      showError(error.message)
      setIsLoading(false)
    }
  }

  return {
    facilitiesData,
    facilityOptions,
    isLoading,
    onCreateCase,
  }
}
